import React, { useEffect, useState } from "react"
import Layout from "../../components/shared-layout";
import { Box, CircularProgress, ListItem, ListItemText, Skeleton, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL } from "../../constants/style";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VmButton from "../../components/shared-button";
import { navigate } from "gatsby";
import useStores from "../../hooks/use-stores";
import { Language } from "../../constants/options";
import moment from "moment";
import { AUDateTimeFormat, AUDDateFormat, CalculateCartItemTotalPrice, CartItemList, GetCartItemNumAsGuest, IsExpiredProduct, RemoveItemFromCartAsGuest } from "../../utilities/cart-item";
import { isWeb } from "../../utilities/platform";
import { GetLanguage, IsAuthed } from "../../utilities/general";
import CheckOutStepper from "../../components/checkout-stepper";
import { RemoveProductFromBookingList } from "../../utilities/travel-planning";

const ShoppingCartIndexPage = observer(({ location }: any) => {
  const { userStore, cartStore, rootStore } = useStores();
  const { t }: any = useTranslation();
  const [cartList, setCartList] = useState<CartItemList>({ totalPrice: 0, productList: [] });
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [refresh, setRefresh] = useState<any>([]);

  useEffect(() => {
    if (IsAuthed()) cartStore.getCartItemList(GetLanguage()).then((list: any) => setCartList(list));
    else if (isWeb && localStorage.CART_LIST) setCartList(JSON.parse(localStorage.CART_LIST));
  }, [userStore.userToken]);

  const onChangeSelectedItems = (id: number) => {
    if (!selectedItems.includes(id)) selectedItems.push(id);
    else {
      let index = selectedItems.indexOf(id);
      selectedItems.splice(index, 1);
    }
    setRefresh([]);
  }

  const onDeleteCartItems = () => {
    if (selectedItems.length > 0) {
      if (IsAuthed()) {
        cartStore.deleteCartItem(selectedItems)
          .then(() => {
            cartStore.getCartItemList(GetLanguage()).then((list: any) => setCartList(list));
            cartStore.getCartItemNumber();
            RemoveProductFromBookingList(selectedItems);
            rootStore.notify(`${selectedItems.length} ${t('ITEMS_REMOVED_FROM_CART')}!`, 'success');
            setSelectedItems([]);
          })
      } else {
        RemoveItemFromCartAsGuest(selectedItems);
        rootStore.notify(`${selectedItems.length} ${t('ITEMS_REMOVED_FROM_CART')}!`, 'success');
        setSelectedItems([]);
        setCartList(JSON.parse(localStorage.CART_LIST));
        GetCartItemNumAsGuest();
        setRefresh([]);
      }
    }
  }

  return (
    <Layout pageName={t('CART')}
      breadCrumb={[
        { to: "/", label: t('HOME') },
        { to: "", label: t('CART') }
      ]} showBanner={false}>
      {cartStore.loadingCartList ? <Skeleton variant="rectangular" width="100%" height={300} />
        : <Box className={PADDING_HORIZONTAL}>
          {cartList.productList && cartList.productList.length == 0 ? <Box sx={ITEM_PERFECT_INLINED} className="justify-center text-3xl">
            <ShoppingCartOutlinedIcon fontSize="large" />
            <p>{t('YOUR_CART_IS_EMPTY')}</p>
          </Box> : <>
            <CheckOutStepper activeStep={0} />
            {cartList.productList && cartList.productList.map((product: any, i: number) => (
              <Box key={`cart_item_${i}`} className="w-full pl-4">
                <ListItem alignItems="flex-start" className="text-5xl" disablePadding>
                  <button onClick={() => onChangeSelectedItems(product.productId)}>
                    <Box className={`mt-2 ${selectedItems.includes(product.productId) ? 'text-sky-400' : 'text-gray-300'}`}>
                      {selectedItems.includes(product.productId) ? <CheckCircleIcon fontSize="inherit" color="inherit" />
                        : <Brightness1OutlinedIcon fontSize="inherit" />}
                    </Box>
                  </button>
                  <ListItemText className="ml-4">
                    <button onClick={() => navigate(`/product-detail?productId=${product.productId}`, { state: { prevPath: isWeb ? location.pathname : '' } })} className="text-left w-full">
                      <Box className="shadow-lg border grid grid-cols-1 sm:grid-cols-3 gap-2 2xl:gap-4 hover:shadow-sm transition-all">
                        <Box className="h-40 sm:h-60"><img src={product.webImage} className="h-full w-full object-cover" /></Box>
                        <Box className="col-span-2 p-2 2xl:p-4 sm:pl-0">
                          <Box sx={ITEM_PERFECT_INLINED} className="block sm:flex justify-between">
                            <p style={{ width: 'fit-content' }} className={`text-white font-light text-xs 2xl:text-sm py-px px-1 rounded-sm ${product.isInstantConfirm ? 'bg-green-500' : 'bg-orange-400'}`}>{product.processingTime}</p>
                            <p className="text-red-500 text-2xl 2xl:text-3xl font-bold">{IsExpiredProduct(product.startDate.split('T')[0], product.scheduleTime) ? t('EXPIRED') : `$${product.subTotal.toFixed(2)}`}</p>
                          </Box>
                          <p className="font-light text-base lg:text-lg 2xl:text-xl 2xl:mt-2">{product.productName}</p>
                          <Box className="grid grid-cols-2 gap-4 mt-2 2xl:mt-4">
                            <Box>
                              <p className="font-light text-gray-500 text-sm sm:text-base 2xl:texl-lg">{product.priceName}</p>
                              <p className="font-light text-gray-500 text-sm sm:text-base 2xl:texl-lg">{t('DEPARTURE_DATE')}: {moment(product.startDate.split('T')[0] + (product.scheduleTime ? `T${product.scheduleTime}` : ""))
                                .format(!product.scheduleTime || product.scheduleTime === "" ? AUDDateFormat : AUDateTimeFormat)}</p>
                            </Box>
                            <Box className="border-l pl-4">
                              {product.totalQuantity.map((ticket: any, i: number) => (
                                <p className="font-light text-gray-500 text-sm sm:text-sm 2xl:texl-base">{ticket.priceName} ${ticket.unitPrice} x {ticket.quantity}</p>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </button>
                  </ListItemText>
                </ListItem>
              </Box>
            ))}
            {/* Select All */}
            <Box>
              <ListItem>
                <button className="text-5xl" onClick={() => setSelectedItems(
                  selectedItems.length == cartList.productList.length ? [] :
                    cartList.productList.map((p: any) => p.productId)
                )}>
                  <Box sx={ITEM_PERFECT_INLINED}>
                    <Box className={`${selectedItems.length == cartList.productList.length ? 'text-sky-400' : 'text-gray-300'}`}>
                      {selectedItems.length == cartList.productList.length ?
                        <CheckCircleIcon fontSize="inherit" color="inherit" /> : <Brightness1OutlinedIcon fontSize="inherit" />}
                    </Box>
                    <p className="text-xl 2xl:text-2xl ml-2">{t('ALL')}</p>
                  </Box>
                </button>
                <VmButton
                  onClick={onDeleteCartItems}
                  className="ml-8 transition-all uppercase"
                  borderColor={selectedItems.length > 0 ? "border-red-500" : "border-gray-300"}
                  bg="white"
                  color={selectedItems.length > 0 ? "text-red-500" : "text-gray-500"}>
                  {t('DELETE_SELECTED_ACTIVITY')}
                </VmButton>
              </ListItem>
            </Box>
            <Box sx={ITEM_PERFECT_INLINED} className="border shadow-lg justify-end p-6 mt-6">
              <p className="mr-6 text-xl 2xl:text-2xl">{t('ORDER_TOTAL')}</p>
              <p className="text-red-500 text-3xl 2xl:text-4xl font-bold">
                AUD ${CalculateCartItemTotalPrice(cartList.productList)}
              </p>
            </Box>
          </>}

          <Box className="flex justify-center mt-8">
            <VmButton onClick={() => navigate("/")} borderColor="border-yellow-200" bg="white" color="black">
              {t('CONTINUE_SHOPPING')}
            </VmButton>
            {cartList.productList.length > 0 && <VmButton className="ml-8"
              disabled={CalculateCartItemTotalPrice(cartList.productList) == 0}
              onClick={() => navigate("/cart/check-out")}>
              {t('PROCEED_TO_CHECKOUT')}
            </VmButton>}
          </Box>
        </Box>}
    </Layout>
  )
});

export default ShoppingCartIndexPage;
